<template>
  <section :id="!!id.length ? id : null" class="blog-highlights row">
    <div class="col-lg-10 offset-lg-1">
      <div class="blog-highlights__container">
        <div class="blog-highlights__title-wrapper">
          <h2 class="blog-highlights__title">
            <div v-for="(item, index) in title.split(' ')" :key="index">
              {{ item }}
            </div>
          </h2>
          <div class="blog-highlights__subtitle">Highlights</div>
        </div>
        <div v-if="items.length > 0" class="blog-highlights__items">
          <div v-for="(item, index) in items" :key="index" class="blog-highlights__item">
            <BlogHighlightsCard v-bind="item" :view-post-text="viewPostText" />
          </div>
        </div>
        <div class="blog-highlights__see-more">
          <nuxt-link :to="journalSlug" class="btn--dark-green">
            {{ viewAllPosts }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BlogHighlightsCard from '~/components/components/BlogHighlightsCard';

export default {
  components: {
    BlogHighlightsCard,
  },
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: 'The Journal' },
    journalSlug: { type: String, default: 'the-journal' },
    viewPostText: { type: String, default: 'Read the full story' },
    viewAllPosts: { type: String, default: 'See more stories' },
    items: {
      type: Array,
      required: false,
      default() {
        return [
          {
            featuredImage: {
              url: 'https://assets-us-01.kc-usercontent.com:443/9a170b3a-a06f-006a-7810-f1c91cdd7d0b/ccc9aa8d-72eb-4b84-ab3e-dbf078aa071c/The-Collective-BBQ-1-2000x0-c-center.jpg?height=1200&width=1200&auto=format',
              description: '',
            },
            tag: {
              contentItem: 'city_living',
              title: 'City living',
            },
            title: '5 ways to make friends in London',
            slug: '5-ways-to-make-new-friends-in-london',
            description: 'test',
          },
          {
            featuredImage: {
              url: 'https://assets-us-01.kc-usercontent.com:443/9a170b3a-a06f-006a-7810-f1c91cdd7d0b/c67911c6-f0be-4c56-a1df-a7bad2d3f997/acoliving.jpg?width=2400&auto=format',
              description: '',
            },
            tag: {
              contentItem: 'city-living',
              title: 'City Living',
            },
            title: '7 questions to ask before you try co-living',
            slug: '7-questions-to-ask-before-you-try-co-living',
            description: 'test',
          },
          {
            featuredImage: {
              url:
                  'https://assets-us-01.kc-usercontent.com:443/9a170b3a-a06f-006a-7810-f1c91cdd7d0b/5eabbcc0-1833-4634-be5b-12d4f7177b66/crystal-hero.jpg?width=2400&auto=format',
              description: '',
            },
            tag: {
              contentItem: 'community',
              title: 'Community',
            },
            title: 'It’s crystal clear: a chat with crystal healer Ruby Larimar',
            slug: 'its-crystal-clear-a-chat-with-crystal-healer-ruby-larimar',
            description: 'test',
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_blog-highlights.scss';
</style>
