<template>
  <div class="blog-card">
    <div class="blog-card__top">
      <div>
        <nuxt-link :to="slug" class="blog-card__image-container" @click.native="gatherData()">
          <KenticoImage :src="imageUrl" :alt="featuredImage.description" :sizes="imageSizes" class="blog-card__image" />
        </nuxt-link>
      </div>
      <div class="blog-card__meta">
        <p v-if="tag" class="blog-card__meta-item text--xx-small text--faux">{{ tagTitle }}</p>
        <p v-if="tag" class="blog-card__meta-item blog-card__meta-date text--xx-small text--faux">{{ formattedDate }}</p>
      </div>
    </div>
    <div class="blog-card__lockup">
      <p class="blog-card__title">{{ title }}</p>
      <p class="blog-card__excerpt" v-html="excerpt" />
    </div>
    <a :href="slug" target="_blank" class="blog-card__cta">{{ viewPostText }} <Chevron class="blog-card__cta-arrow" /></a>
  </div>
</template>

<script>
import Moment from 'moment';
import Chevron from '~/assets/icons/chevron.svg?inline';
import { BREAKPOINTS, BREAKPOINT_MD, BREAKPOINT_LG } from '~/utils/constants';
import { clickedCta } from '~/utils/gtm-events.js';

export default {
  components: { Chevron },
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    date: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    slug: {
      type: String,
      required: true,
    },
    featuredImage: {
      type: Object,
      required: true,
      default() {
        return {
          url: { type: String, required: true },
          description: { type: String, required: true },
        };
      },
    },
    tag: {
      type: Object,
      default() {
        return {};
      },
    },
    viewPostText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageSizes: {
        default: [320, 448],
        sizes: [
          { breakpoint: BREAKPOINTS[BREAKPOINT_MD], width: 320, height: 448 },
          { breakpoint: BREAKPOINTS[BREAKPOINT_LG], width: 320, height: 448 },
        ],
      },
    };
  },
  computed: {
    formattedDate() {
      let momentDate = '';
      if (this.date.length > 0) {
        momentDate = Moment(this.date);
      }
      return momentDate.format('Do MMMM Y');
    },
    imageUrl() {
      if (this.featuredImage && this.featuredImage.url) {
        return this.featuredImage.url;
      }

      return null;
    },
    tagTitle() {
      return this.tag.title;
    },
  },
  methods: {
    gatherData() {
      clickedCta(this.$gtm, this.viewPostText, null, null, this.slug);
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/components/_blog-card.scss';
</style>
